<template>
  <div class="schoolMessage">
    <el-form :model="schoolMessageForm"
             label-width="100px"
             ref="schoolMessageForm">
      <el-form-item label="学校名称："
                    prop="sname">
        <el-input v-model="schoolMessageForm.sname"
                  class="input-box"></el-input>
      </el-form-item>
      <el-form-item label="校训："
                    prop="schoolmotto">
        <el-input v-model="schoolMessageForm.schoolmotto"
                  class="input-box"></el-input>
      </el-form-item>
      <el-form-item label="学校理念："
                    prop="schoolmotto">
        <el-input v-model="schoolMessageForm.idea"
                  class="input-box"></el-input>
      </el-form-item>
      <el-form-item label="学校章程："
                    prop="schoolmotto">
        <el-input v-model="schoolMessageForm.rules"
                  class="input-box"></el-input>
      </el-form-item>
      <el-form-item label="学校简介:"
                    prop="schooltext">
        <el-input v-model="schoolMessageForm.schooltext"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 20 }"
                  class="schooltext-box"></el-input>
      </el-form-item>
      <el-form-item label="学校logo：">
        <el-upload class="avatar-uploader"
                   action="/api/base/uploadByOss.do"
                   :data="{path:'schoolMessage',name:''}"
                   :show-file-list="false"
                   :headers="{Authorization:token}"
                   :on-success="schoolLogoBeforeUploadSuccess">
          <img v-if="schoolMessageForm.logo"
               :src="schoolMessageForm.logoUrl"
               class="avatar">
          <i v-else
             class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="学校简图：">
        <el-upload class="avatar-uploader"
                   action="/api/base/uploadByOss.do"
                   :data="{path:'schoolMessage',name:''}"
                   :show-file-list="false"
                   :headers="{Authorization:token}"
                   :on-success="photourlSuccess">
          <img v-if="schoolMessageForm.photourl"
               :src="schoolMessageForm.photo"
               class="avatar">
          <i v-else
             class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-button type="primary"
               class="button"
               @click="modifyMessage">提 交</el-button>
  </div>
</template>

<script>
export default {
  name: "schoolMessage",
  data() {
    return {
      schoolMessageForm: {},
      token: sessionStorage.getItem("token"),
      //   currentFileName: "",
    };
  },
  created() {
    this.loadSchoolMessage();
  },
  methods: {
    loadSchoolMessage() {
      const that = this;
      this.$post("/media/querySchool.do", {})
        .then((res) => {
          that.schoolMessageForm = res.data;
          that.schoolMessageForm.logoUrl =
            this.$fileUploadDomain + res.data.logo;
          that.schoolMessageForm.photo =
            this.$fileUploadDomain + res.data.photourl;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            $message: err.message,
          });
        });
    },
    modifyMessage() {
      const that = this;
      this.$confirm("是否确认提交", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateSchool.do", {
            id: this.schoolMessageForm.id,
            sname: this.schoolMessageForm.sname,
            photourl: this.schoolMessageForm.photourl,
            logo: this.schoolMessageForm.logo,
            schoolmotto: this.schoolMessageForm.schoolmotto,
            schooltext: this.schoolMessageForm.schooltext,
            idea: this.schoolMessageForm.idea,
            rules: this.schoolMessageForm.rules,
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: res.message,
              });
              that.loadSchoolMessage();
              this.$store.commit(
                "modifySchoolName",
                this.schoolMessageForm.sname
              );
              this.$store.commit("modifyLogo", this.schoolMessageForm.logo);
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    schoolLogoBeforeUploadSuccess(res, file) {
      this.schoolMessageForm.logo = file.response.message;
      this.schoolMessageForm.logoUrl =
        this.$fileUploadDomain + file.response.message;
    },
    photourlSuccess(res, file) {
      this.schoolMessageForm.photourl = file.response.message;
      this.schoolMessageForm.photo =
        this.$fileUploadDomain + file.response.message;
    },
  },
};
</script>

<style scoped lang="scss">
.schooltext-box {
  width: 700px;
  overflow-x: auto;
}
.input-box {
  width: 700px;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border: 1px dashed #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.button {
  margin-left: 700px;
}
</style>
